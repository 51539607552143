import { useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '@front/volcanion/provider'

const useListener = (provider_name, listener_id, listener_type) => {
  return useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider(provider_name)?.prepareAttachedSubscription(listener_id, listener_type), [provider_name, listener_id, listener_type]),
    useCallback(() => ProviderInstance.getProvider(provider_name)?.getListener(listener_id), [provider_name, listener_id]),
    null,
    _.identity,
    _.isEqual
  )
}

export default useListener