import "amazon-connect-streams"

import Listener from "@front/volcanion/classes/listener"

class IVRListener extends Listener {
  constructor(provider, listener_id, info, options) {
    super(provider, listener_id, info, options)
    this.agent = null
    this.is_loggedin = false
    this.endpoints = []
  }

  getAgent() {
    return this.agent
  }

  setAgent(value) {
    this.agent = value
    this.notifyCallbacks({ name: 'ivr_agent', key: 'agent' })
  }

  getAgentName() {
    return this.getAgent()?.getName()
  }

  getAgentStatus() {
    return this.getAgent()?.getState()
  }

  getDialableCountries() {
    return this.getAgent()?.getDialableCountries()
  }

  getAgentStatusList() {
    return this.getAgent()?.getAgentStates()
  }

  isAutoAccept() {
    const config = this.getAgent()?.getConfiguration()
    return config?.softphoneAutoAccept
  }

  getContact() {
    const contacts = this.getAgent()?.getContacts()
    return contacts?.[0]
  }

  getActiveConnections() {
    return this.getContact()?.getActiveConnections()
  }

  getActiveInitialConnection() {
    return this.getContact()?.getActiveInitialConnection()
  }

  getThirdPartyConnection() {
    return this.getContact()?.getSingleActiveThirdPartyConnection()
  }

  isMute() {
    return this.getContact()?.getAgentConnection()?.isMute()
  }

  async endCall(isThirdParty) {
    const connection = !!isThirdParty ? this.getThirdPartyConnection() : this.getActiveInitialConnection()
    return new Promise((resolve, reject) => connection?.destroy({ success: resolve, failure: reject }))
  }

  getPhoneNumber(isThirdParty) {
    const endpoint = !!isThirdParty ? this.getThirdPartyConnection()?.getEndpoint() : this.getActiveInitialConnection()?.getEndpoint()
    return endpoint?.phoneNumber
  }

  getConnectionStatus(isThirdParty) {
    return !!isThirdParty ? this.getThirdPartyConnection()?.getState() : this.getActiveInitialConnection()?.getState()
  }

  isConnectionOnHold(isThirdParty) {
    return !!isThirdParty ? this.getThirdPartyConnection()?.isOnHold() : this.getActiveInitialConnection()?.isOnHold()
  }

  async setConnectionResumeStatus(isThirdParty) {
    const connection = !!isThirdParty ? this.getThirdPartyConnection() : this.getActiveInitialConnection()
    return new Promise((resolve, reject) => connection?.resume({ success: resolve, failure: reject }))
  }

  async setConnectionHoldStatus(isThirdParty) {
    const connection = !!isThirdParty ? this.getThirdPartyConnection() : this.getActiveInitialConnection()
    return new Promise((resolve, reject) => connection?.hold({ success: resolve, failure: reject }))
  }

  async acceptCall() {
    return new Promise((resolve, reject) => this.getContact().accept({ success: resolve, failure: reject }))
  }

  async rejectCall() {
    return new Promise((resolve, reject) => this.getContact().reject({ success: resolve, failure: reject }))
  }

  getEndpoints() {
    return this.endpoints
  }

  setEndpoints(value) {
    this.endpoints = value
    this.notifyCallbacks({ name: 'ivr_agent', key: 'endpoints' })
  }

  async loadEndpoints(agent) {
    const arns = agent.getAllQueueARNs()
    const endpoints = await new Promise((resolve, reject) => this.getAgent()?.getEndpoints(arns, { success: (data) => resolve(data?.endpoints), failure: reject }))
    this.setEndpoints(endpoints)
    return this
  }

  hasActiveFirstConnection() {
    return !!this.getContact()?.getActiveInitialConnection() && !!this.getContact()?.getAgentConnection()?.isActive()
  }

  hasThirdPartyConnection() {
    return !!this.getContact()?.getSingleActiveThirdPartyConnection() && !!this.getContact()?.getAgentConnection()?.isActive()
  }

  isConference() {
    return !this.getActiveInitialConnection()?.isOnHold() && !this.getThirdPartyConnection()?.isOnHold()
  }

  async toggleActiveConnections() {
    return new Promise((resolve, reject) => this.getContact().toggleActiveConnections({ success: resolve, failure: reject }))
  }

  async conferenceConnections() {
    return new Promise((resolve, reject) => this.getContact().conferenceConnections({ success: resolve, failure: reject }))
  }

  async endAgentConnection() {
    return new Promise((resolve, reject) => this.getContact().getAgentConnection()?.destroy({ success: resolve, failure: reject }))
  }

  async setAgentStatus(value, enqueueNextState = false) {
    const matched_state = this.getAgent().getAgentStates().find(({ name }) => name === value)
    await new Promise((resolve, reject) => this.getAgent().setState(matched_state, { success: resolve, failure: reject }, { enqueueNextState }))
    return this
  }

  async connectByEndpoint(endpointId) {
    const endpoint = _.find(this.getEndpoints(), (e) => e.endpointId === endpointId)
    return this.connectEndpoint(endpoint)
  }

  async connectByPhone(inputPhone) {
    const phone = connect.Endpoint.byPhoneNumber(inputPhone)
    return this.connectEndpoint(phone)
  }

  async connectEndpoint(endpoint) {
    const contacts = this.getAgent()?.getContacts()
    if (!_.isEmpty(contacts)) {
      return new Promise((resolve, reject) => contacts?.[0]?.addConnection(endpoint, { success: resolve, failure: reject }))
    }
    else {
      return new Promise((resolve, reject) => this.getAgent()?.connect(endpoint, { success: resolve, failure: reject }))
    }
  }

  setAgentMute() {
    this.getAgent()?.mute()
  }

  setAgentUnmute() {
    this.getAgent()?.unmute()
  }

  onContactEvent(contact) {
    contact.onConnected(() => this.notifyCallbacks({ name: 'ivr_contact', key: 'onConnected' }))
    contact.onRefresh(() => this.notifyCallbacks({ name: 'ivr_contact', key: 'onRefresh' }))
    contact.onEnded(() => this.notifyCallbacks({ name: 'ivr_contact', key: 'onEnded' }))
    contact.onError(() => this.notifyCallbacks({ name: 'ivr_contact', key: 'onError' }))
  }

  async onAgentEvent(agent) {
    this.setAgent(agent)
    agent.onStateChange(connect.hitch(this, () => this.notifyCallbacks({ name: 'ivr_agent', key: 'onStateChange' })))
    agent.onMuteToggle(connect.hitch(this, () => this.notifyCallbacks({ name: 'ivr_agent', key: 'onMute' })))
    agent.onEnqueuedNextState(connect.hitch(this, () => this.notifyCallbacks({ name: 'ivr_agent', key: 'onNextState' })))
    await this.loadEndpoints(agent).catch(console.error)
    const eventBus = connect.core.getEventBus()

    eventBus.subscribe(connect.EventType.TERMINATE, () => {
      const status_list = agent.getAgentStates()
      const state = _.find(status_list, (status) => status.type === 'offline')
      agent.setState(state)
      this.onDestroy()
    })
    this.setIsLoggedin(true)
  }

  onCoreInit() {
    connect.contact(connect.hitch(this, this.onContactEvent))
    connect.agent(connect.hitch(this, this.onAgentEvent))
  }

  onAuthFail() {
    this.setIsLoggedin(false)
  }

  setIsLoggedin(value) {
    this.is_loggedin = value
    return this.notifyCallbacks({})
  }

  isLoggedIn() {
    return this.is_loggedin
  }

  // Abstract functions
  onDestroy(event) {
    connect.core.terminate()
    return super.onDestroy(event)
  }
  onCreate(event) {
    const CppURL = this.getParentProvider().getProvider('relay').getConstant('CPP_URL')
    // const CppURL = "https://fleetizen.awsapps.com"

    const containerDiv = document.getElementById(this.getOption('container_id'))
    if (!CppURL) return _.noop
    connect.core.initCCP(containerDiv, {
      ccpUrl: `${CppURL}/connect/ccp-v2#`,            // REQUIRED
      loginPopup: true,               // optional, defaults to `true`
      loginPopupAutoClose: true,      // optional, defaults to `false`
      loginOptions: {                 // optional, if provided opens login in new window
        autoClose: true,              // optional, defaults to `false`
        height: 600,                  // optional, defaults to 578
        width: 400,                   // optional, defaults to 433
        top: 0,                       // optional, defaults to 0
        left: 0                       // optional, defaults to 0
      },
      region: "eu-central-1",         // REQUIRED for `CHAT`, optional otherwise
      softphone: {                    // optional, defaults below apply if not provided
        allowFramedSoftphone: true,   // optional, defaults to false
        disableRingtone: false,       // optional, defaults to false
        // ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
      },
      pageOptions: {                  // optional
        enableAudioDeviceSettings: false, //optional, defaults to 'false'
        enableVideoDeviceSettings: false, //optional, defaults to 'false'
        enablePhoneTypeSettings: true //optional, defaults to 'true' 
      },
      storageAccess: {
        canRequest: false,
        mode: 'default'
      }
    })
    connect.core.onAuthFail(connect.hitch(this, this.onAuthFail))
    connect.core.onAccessDenied(connect.hitch(this, this.onAuthFail))
    connect.core.onInitialized(connect.hitch(this, this.onCoreInit))

    return this.setIsReady(true)
  }
}

export default IVRListener