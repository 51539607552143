import { useCallback } from 'react'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '@front/volcanion/provider'

const useListenerSelector = (provider_name, listener_id, listener_type, selector) => {
  return useSyncExternalStoreWithSelector(
    useCallback(ProviderInstance.getProvider(provider_name)?.prepareAttachedSubscription(listener_id, listener_type), [provider_name, listener_id, listener_type]),
    useCallback(() => selector(ProviderInstance.getProvider(provider_name)?.getListener(listener_id)), [provider_name, listener_id, selector]),
    null,
    _.identity,
    _.isEqual
  )
}

export default useListenerSelector