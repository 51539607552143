import IVRProvider from "./provider";
import IVRListener from "./listener";
import { ProviderInstance } from '@front/volcanion/provider'
import { useCallback, useEffect, useSyncExternalStore } from "react";
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import _ from 'lodash'
import { useProviderListener } from "@front/volcanion/hooks";


const IVRRoot = ({ children, name, options }) => {

  const isProviderReady = useSyncExternalStore(
    useCallback(ProviderInstance.prepareDynamicProvider(new IVRProvider(name)), [name]),
    useCallback(() => ProviderInstance.getProviderStatus(name), [])
  )

  const [{ isReady }] = useSyncExternalStoreWithSelector(
    useProviderListener(name, name, {}, options, [isProviderReady, options], [], []),
    useCallback(() => [
      ProviderInstance.getProvider(name)?.getFullListenerStatus(name) || {}
    ], [name]),
    null,
    _.identity,
    _.isEqual
  )

  useEffect(() => () => ProviderInstance.removeProvider(name), [])


  if (isReady && isProviderReady)
    return children
  return null
}

export default IVRRoot

export {
  IVRRoot,
  IVRProvider,
  IVRListener
}