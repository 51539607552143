import Provider from '@front/volcanion/classes/providers'
import IVRListener from './listener'

class IVRProvider extends Provider {
  getListenerClass(info, options) {
    return IVRListener
  }
  async init() {
    return super.init(true)
  }
}

export default IVRProvider